<template>
  <svg width="66" height="85" viewBox="0 0 66 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1138_20582)">
      <path
        d="M11.3 20.3C11.3 16.9667 12.0333 14 13.5 11.4C14.9667 8.8 16.9333 6.76666 19.4 5.3C24.3333 2.36666 29.5 0.899996 34.9 0.899996C42.1 0.899996 48 2.6 52.6 6C57.2667 9.33333 59.5667 14.1333 59.5 20.4C59.3 28.6667 55.1333 34.5 47 37.9C51.4 38.7667 54.9667 40.7 57.7 43.7C60.4333 46.7 61.8 50.3333 61.8 54.6C61.8 62.0667 58.9333 67.6667 53.2 71.4C47.4667 75.1333 40.8667 77 33.4 77C20.5333 77 10.7333 72.4 4 63.2L13.7 54.2C15.2333 57.6 17.9 60.6 21.7 63.2C25.6333 65.8667 29.4667 67.2 33.2 67.2C37 67.2 40.0667 66.0667 42.4 63.8C44.7333 61.4667 45.9 58.6 45.9 55.2C45.9 53.0667 45.3333 51.2 44.2 49.6C41.3333 45.7333 35.6667 43.8 27.2 43.8C26.8 43.8 26.4 43.8 26 43.8H19.9L20.7 34.7C25.5667 34.7 29.6333 34.5333 32.9 34.2C36.2333 33.8667 39.1 32.6667 41.5 30.6C44.1 28.2667 45.4 25.1 45.4 21.1C45.4 18.3 44.4 15.8333 42.4 13.7C40.4667 11.5 37.6333 10.3667 33.9 10.3C30.7667 10.4333 28.4333 11.4 26.9 13.2C25.4333 14.9333 24.7 16.7667 24.7 18.7C24.7 20.6333 24.9 22.2 25.3 23.4H11.5C11.3667 22.0667 11.3 21.0333 11.3 20.3Z"
        fill="url(#paint0_radial_1138_20582)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1138_20582"
        x="0"
        y="0.898438"
        width="65.8008"
        height="84.1016"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.668713 0 0 0 0 0.700824 0 0 0 0 0.728923 0 0 0 0.13 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1138_20582" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1138_20582"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1138_20582"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(66.5 82.75) rotate(-127.606) scale(108.156 88.3806)"
      >
        <stop offset="0.13034" stop-color="var(--colors-gradient-primary_solid-first)" />
        <stop offset="0.416676" stop-color="var(--colors-gradient-primary_solid-second)" />
        <stop offset="0.645863" stop-color="var(--colors-gradient-primary_solid-third)" />
        <stop offset="0.901448" stop-color="var(--colors-gradient-primary_solid-fourth)" />
      </radialGradient>
    </defs>
  </svg>
</template>
