<template>
  <svg width="64" height="86" viewBox="0 0 64 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1138_20565)">
      <path
        d="M58.5 54.4C58.9 56.0667 59.1 57.4 59.1 58.4C59.1 65.5333 57.5667 70.5 54.5 73.3C51.5 76.1 47.4333 77.5 42.3 77.5C38.6333 77.5 34.3 76.9 29.3 75.7C24.3 74.4333 20.8 73.8 18.8 73.8C18.6667 73.8 18.5667 73.8 18.5 73.8C14.4333 73.8 10.8667 74.8667 7.8 77L4.1 68.2H4C5.6 66.1333 8.8 62.9667 13.6 58.7C18.4 54.3667 21.3 51.7333 22.3 50.8C23.3667 49.8 24.8333 48.5333 26.7 47C28.5667 45.4 30.0333 44.1 31.1 43.1C32.2333 42.1 33.6 40.7333 35.2 39C36.8 37.2 38.0667 35.5333 39 34C41.8 29.4667 43.2 25.3333 43.2 21.6C43.2 17.8667 42.2333 15.0667 40.3 13.2C38.3667 11.3333 36.1667 10.3667 33.7 10.3C30.4333 10.4333 27.9667 11.4 26.3 13.2C24.6333 15 23.7667 16.9 23.7 18.9C23.6333 20.9 23.7667 22.4 24.1 23.4H10.5C9.9 17.8 11.2333 13.0667 14.5 9.2C19.0333 3.66666 25.6 0.899996 34.2 0.899996C41.4 0.899996 47.2667 2.7 51.8 6.3C56.3333 9.9 58.6 15.0333 58.6 21.7C58.6 28.0333 55.8333 34.3333 50.3 40.6C48.4333 42.6667 47.0667 44.1 46.2 44.9C39.6667 50.9667 32.7667 56.6667 25.5 62C26.7667 62.2 29.5667 62.7333 33.9 63.6C38.5667 64.4667 41.5333 64.9 42.8 64.9C45.5333 64.9 47.6 64 49 62.2C50.4667 60.4 51.6333 57.8 52.5 54.4H58.5Z"
        fill="url(#paint0_radial_1138_20565)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1138_20565"
        x="0"
        y="0.898438"
        width="63.1016"
        height="84.6016"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.668713 0 0 0 0 0.700824 0 0 0 0 0.728923 0 0 0 0.13 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1138_20565" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1138_20565"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1138_20565"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(63.5 82.75) rotate(-125.889) scale(105.762 84.9031)"
      >
        <stop offset="0.13034" stop-color="var(--colors-gradient-primary_solid-first)" />
        <stop offset="0.416676" stop-color="var(--colors-gradient-primary_solid-second)" />
        <stop offset="0.645863" stop-color="var(--colors-gradient-primary_solid-third)" />
        <stop offset="0.901448" stop-color="var(--colors-gradient-primary_solid-fourth)" />
      </radialGradient>
    </defs>
  </svg>
</template>
