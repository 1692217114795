<template>
  <svg width="57" height="85" viewBox="0 0 57 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1138_20548)">
      <path
        d="M51 77H4.7L6.1 66.2H22.1L28.3 20.6L9.9 21.7L11 12.7C23.2667 9.56666 30.5667 5.63333 32.9 0.899996H45.1L36.6 66.2H52.2L51 77Z"
        fill="url(#paint0_radial_1138_20548)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1138_20548"
        x="0.699219"
        y="0.898438"
        width="55.5"
        height="84.1016"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.668713 0 0 0 0 0.700824 0 0 0 0 0.728923 0 0 0 0.13 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1138_20548" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1138_20548"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1138_20548"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(54.5 82.75) rotate(-120.762) scale(99.7128 74.0767)"
      >
        <stop offset="0.13034" stop-color="var(--colors-gradient-primary_solid-first)" />
        <stop offset="0.416676" stop-color="var(--colors-gradient-primary_solid-second)" />
        <stop offset="0.645863" stop-color="var(--colors-gradient-primary_solid-third)" />
        <stop offset="0.901448" stop-color="var(--colors-gradient-primary_solid-fourth)" />
      </radialGradient>
    </defs>
  </svg>
</template>
