<template>
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 333 305"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_0_1)">
      <path
        d="M5.54914 253.061C15.4694 135.351 24.5694 78.3089 42.9024 17.3159C46.1247 6.59552 56.6469 -0.181255 67.7774 1.0731C145.221 9.8007 241.712 21.677 311.605 39.0301C321.372 41.455 328.265 50.072 328.328 60.1225C328.641 110.522 318.781 157.949 289.048 277.846C286.25 289.131 275.647 296.788 264.062 295.838C253.131 294.941 240.814 293.961 227.632 292.913C155.108 287.144 56.4133 279.293 18.3077 271.726C9.58056 269.993 4.79271 262.037 5.54914 253.061Z"
        stroke="var(--backgroundColor-primary)"
        shape-rendering="crispEdges"
      />
      <path
        d="M5.54914 253.061C15.4694 135.351 24.5694 78.3089 42.9024 17.3159C46.1247 6.59552 56.6469 -0.181255 67.7774 1.0731C145.221 9.8007 241.712 21.677 311.605 39.0301C321.372 41.455 328.265 50.072 328.328 60.1225C328.641 110.522 318.781 157.949 289.048 277.846C286.25 289.131 275.647 296.788 264.062 295.838C253.131 294.941 240.814 293.961 227.632 292.913C155.108 287.144 56.4133 279.293 18.3077 271.726C9.58056 269.993 4.79271 262.037 5.54914 253.061Z"
        stroke="url(#paint0_radial_0_1)"
        shape-rendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_d_0_1)">
      <path
        d="M32.7691 277.181C10.8121 162.116 5.60585 112.563 5.70281 50.8096C5.72052 39.5303 13.9258 29.9906 25.0598 28.1263C92.1166 16.8985 175.021 4.05779 238.03 2.61652C248.168 2.38462 257.133 9.04484 259.814 18.8249C273.659 69.3407 279.943 127.968 285.991 252.011C286.557 263.621 278.517 273.903 267.046 275.627C204.28 285.065 91.2471 293.497 49.4465 292.434C40.7923 292.214 34.4081 285.77 32.7691 277.181Z"
        stroke="var(--backgroundColor-primary)"
        shape-rendering="crispEdges"
      />
      <path
        d="M32.7691 277.181C10.8121 162.116 5.60585 112.563 5.70281 50.8096C5.72052 39.5303 13.9258 29.9906 25.0598 28.1263C92.1166 16.8985 175.021 4.05779 238.03 2.61652C248.168 2.38462 257.133 9.04484 259.814 18.8249C273.659 69.3407 279.943 127.968 285.991 252.011C286.557 263.621 278.517 273.903 267.046 275.627C204.28 285.065 91.2471 293.497 49.4465 292.434C40.7923 292.214 34.4081 285.77 32.7691 277.181Z"
        stroke="url(#paint1_radial_0_1)"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_0_1"
        x="0.839962"
        y="0.425781"
        width="332.125"
        height="304.25"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.12879" />
        <feGaussianBlur stdDeviation="2.06439" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.668713 0 0 0 0 0.700824 0 0 0 0 0.728923 0 0 0 0.13 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
      </filter>
      <filter
        id="filter1_d_0_1"
        x="1.07434"
        y="2.10938"
        width="289.57"
        height="299.172"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.12879" />
        <feGaussianBlur stdDeviation="2.06439" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.668713 0 0 0 0 0.700824 0 0 0 0 0.728923 0 0 0 0.13 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_0_1"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.0447 143.895) rotate(4.93453) scale(296.595 305.527)"
      >
        <stop
          offset="0.13034"
          stop-color="var(--colors-gradient-primary_solid-first)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.416676"
          stop-color="var(--colors-gradient-primary_solid-second)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.645863"
          stop-color="var(--colors-gradient-primary_solid-third)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.901448"
          stop-color="var(--colors-gradient-primary_solid-fourth)"
          stop-opacity="0.3"
        />
      </radialGradient>
      <radialGradient
        id="paint1_radial_0_1"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.7946 177.546) rotate(-11.2057) scale(263.218 304.668)"
      >
        <stop
          offset="0.13034"
          stop-color="var(--colors-gradient-primary_solid-first)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.416676"
          stop-color="var(--colors-gradient-primary_solid-second)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.645863"
          stop-color="var(--colors-gradient-primary_solid-third)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.901448"
          stop-color="var(--colors-gradient-primary_solid-fourth)"
          stop-opacity="0.3"
        />
      </radialGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
