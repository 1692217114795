<template>
  <svg
    viewBox="0 0 282 184"
    fill="none"
    preserveAspectRatio="xMinYMin"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M259.436 7.91767C195.12 2.57032 139.834 2.63812 18.6007 8.484C13.5694 8.72661 8.02108 11.6492 6.81016 17.8108C4.41704 29.9879 3.74596 55.7539 4.07995 83.1713C4.4159 110.749 5.78082 140.526 7.59947 161.018C8.30328 168.949 14.8559 174.859 22.6192 175.225C151.799 181.323 208.684 181.669 261.038 175.752C267.915 174.975 273.576 169.766 274.618 162.786C280.721 121.902 277.346 66.3344 273.977 22.5595C273.377 14.7769 267.226 8.56532 259.436 7.91767Z"
      stroke="var(--backgroundColor-primary)"
      stroke-width="8"
    />
  </svg>
</template>
