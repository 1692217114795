<template>
  <template v-if="isMdAndUp">
    <div class="mb-9 grid grid-cols-4 gap-6">
      <EventCardBigSkeleton v-for="first in 4" :key="'first' + first" />
    </div>
    <div class="mb-9 grid grid-cols-5 gap-6">
      <EventCardBigSkeleton v-for="second in 5" :key="'second' + second" />
    </div>
  </template>
  <Tempalte v-else>
    <div class="mb-9 grid grid-cols-2 gap-3">
      <EventCardBigSkeleton
        v-for="third in 10"
        :key="'third' + third"
        :class="{ 'col-span-2': third === 1 || !(third % 6) }"
      />
    </div>
  </Tempalte>
</template>

<script lang="ts" setup>
import { EventCardBigSkeleton } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'

const {
  md: [isMdAndUp]
} = useBreakpoint()
</script>
