<template>
  <div class="relative flex w-full">
    <Component :is="numbersMap[number]" class="relative z-20" />
    <RouterLink
      :to="{ name: 'EventView', params: { id: value.id } }"
      class="relative z-30 w-full -translate-x-4.5 p-6 md:-translate-x-2.5"
    >
      <TopBackgroundLines class="absolute left-[-5%] top-[-2%] z-10 hidden size-[106%] md:block" />
      <TopBackgroundBack class="absolute left-0 top-0 z-10 size-full" />
      <div class="group relative z-10 min-w-[168px]">
        <EventCard :value="value" size="big" class="relative" />
        <ImageBorder class="absolute left-[-3%] top-[-3%] z-30 w-[106%]" />
      </div>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { type Event, EventCard } from '@/5_entities/Event'
import {
  NumberOneSvg,
  NumberTwoSvg,
  NumberThreeSvg,
  TopBackgroundBack,
  TopBackgroundLines,
  ImageBorder
} from '../Svg'

type PropType = {
  value: Event
  number: number
}

defineProps<PropType>()

const numbersMap = [NumberOneSvg, NumberTwoSvg, NumberThreeSvg]
</script>
