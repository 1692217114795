<template>
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 318 289"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1262_2131)">
      <path
        d="M310.359 257.999C309.422 266.775 302.173 272.424 293.367 273.026C163.05 281.929 99.9322 282.209 30.3608 274.433C18.8735 273.149 10.0933 263.683 9.48091 252.14C5.68915 180.667 2.09672 92.0639 8.55991 26.0344C9.57162 15.6985 17.4604 7.43681 27.7342 5.91927C84.8621 -2.51904 149.457 -1.84226 287.013 7.35419C298.853 8.14572 308.382 17.4756 309.077 29.321C312.969 95.714 314.92 215.279 310.359 257.999Z"
        fill="var(--backgroundColor-primary)"
      />
      <path
        d="M310.359 257.999C309.422 266.775 302.173 272.424 293.367 273.026C163.05 281.929 99.9322 282.209 30.3608 274.433C18.8735 273.149 10.0933 263.683 9.48091 252.14C5.68915 180.667 2.09672 92.0639 8.55991 26.0344C9.57162 15.6985 17.4604 7.43681 27.7342 5.91927C84.8621 -2.51904 149.457 -1.84226 287.013 7.35419C298.853 8.14572 308.382 17.4756 309.077 29.321C312.969 95.714 314.92 215.279 310.359 257.999Z"
        fill="url(#paint0_radial_1262_2131)"
      />
      <path
        d="M293.332 272.511C163.025 281.414 99.9432 281.691 30.4181 273.92C19.1862 272.664 10.5955 263.408 9.99628 252.113C6.20423 180.634 2.61476 92.0693 9.07354 26.0847C10.0621 15.9853 17.7695 7.91285 27.8096 6.42985C84.8763 -1.99944 149.421 -1.32747 286.979 7.86914C298.567 8.64386 307.883 17.7735 308.561 29.3512C312.453 95.7434 314.402 215.271 309.846 257.944C308.941 266.419 301.944 271.923 293.332 272.511Z"
        stroke="var(--borderColor-secondary)"
        stroke-opacity="0.5"
        stroke-width="1.0322"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1262_2131"
        x="0.871212"
        y="0"
        width="316.258"
        height="288.258"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.12879" />
        <feGaussianBlur stdDeviation="2.06439" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.668713 0 0 0 0 0.700824 0 0 0 0 0.728923 0 0 0 0.13 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1262_2131" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1262_2131"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1262_2131"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(171.256 274.096) rotate(-94.8002) scale(275.519 331.963)"
      >
        <stop
          offset="0.13034"
          stop-color="var(--colors-gradient-primary_solid-first)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.416676"
          stop-color="var(--colors-gradient-primary_solid-second)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.645863"
          stop-color="var(--colors-gradient-primary_solid-third)"
          stop-opacity="0.3"
        />
        <stop
          offset="0.901448"
          stop-color="var(--colors-gradient-primary_solid-fourth)"
          stop-opacity="0.3"
        />
      </radialGradient>
    </defs>
  </svg>
</template>
