<template>
  <UiContainer>
    <div ref="mainCompilationsContainer" class="w-full">
      <template v-if="value && !loading">
        <ul
          v-if="value.length > 1"
          class="scrollbar-hidden sticky top-0 z-30 mb-6 flex w-full items-center overflow-x-scroll bg-primary py-4 md:static md:justify-center lg:pt-0"
        >
          <li v-for="(compilationName, index) in value" :key="compilationName.title">
            <UiButton
              severity="tertiary"
              :size="isMdAndUp ? 'big' : 'small'"
              class="relative overflow-hidden"
              @click="setActiveCompilationTab(index)"
            >
              <TransitionFade>
                <UiGradientBackground v-if="isActiveCompilationTab(index)" severity="primary" />
              </TransitionFade>

              <span class="relative">{{ compilationName.title }}</span>
            </UiButton>
          </li>
        </ul>
        <template v-if="isMdAndUp">
          <div v-if="itemsForDesktop" class="flex flex-col gap-9">
            <ul
              v-for="(part, index) in itemsForDesktop"
              :key="'part' + index"
              class="grid gap-6"
              :class="{ 'grid-cols-4': index === 0, 'grid-cols-5': index === 1 }"
            >
              <li v-for="event in part" :key="event.object.id">
                <RouterLink :to="{ name: 'EventView', params: { id: event.object.id } }">
                  <EventCard :value="event.object as Event" size="big" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </template>
        <template v-else-if="activeCompilation">
          <ul class="grid grid-cols-2 gap-3">
            <li
              v-for="(item, index) in activeCompilation.items"
              :key="item.object.id + 'mobile'"
              :class="{ 'col-span-2': !index || !(index % 5) }"
            >
              <RouterLink :to="{ name: 'EventView', params: { id: item.object.id } }">
                <EventCard :value="item.object as Event" size="big" />
              </RouterLink>
            </li>
          </ul>
        </template>
      </template>

      <template v-else>
        <HomeMainCompilationSkeleton />
      </template>
    </div>
  </UiContainer>
</template>

<script setup lang="ts">
import { TransitionFade } from '@morev/vue-transitions'
import { storeToRefs } from 'pinia'
import { computed, useTemplateRef, watch } from 'vue'
import { type Compilation } from '@/5_entities/Compilation'
import { type Event, EventCard } from '@/5_entities/Event'
import { useHome } from '@/5_entities/Home'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiContainer, UiGradientBackground } from '@/6_shared/ui'
import HomeMainCompilationSkeleton from './HomeMainCompilationSkeleton.vue'

type PropType = {
  value?: Compilation[]
  loading: boolean
}

const props = defineProps<PropType>()
const {
  md: [isMdAndUp]
} = useBreakpoint()
const { setActiveCompilation } = useHome()
const { activeCompilationTab } = storeToRefs(useHome())

watch(
  () => props.value,
  (val) => {
    if (!val) return
    val.length - 1 < activeCompilationTab.value && setActiveCompilation(0)
  }
)

const setActiveCompilationTab = (index: number) => {
  scrollToTop()
  setActiveCompilation(index)
}
const isActiveCompilationTab = (index: number) => {
  return activeCompilationTab.value === index
}
const activeCompilation = computed(() => props.value && props.value[activeCompilationTab.value])
const itemsForDesktop = computed(() => {
  if (!activeCompilation.value) return

  return [activeCompilation.value.items.slice(0, 4), activeCompilation.value.items.slice(4)]
})

const mainCompilationsContainer = useTemplateRef<typeof UiContainer>('mainCompilationsContainer')
const scrollToTop = () => {
  if (!mainCompilationsContainer.value || isMdAndUp.value) return

  mainCompilationsContainer.value.scrollIntoView({
    behavior: 'smooth'
  })
}
</script>
